var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("项目名称")]),_c('ContractSelector',{on:{"change":(value) => {
                  _vm.selectedProject = value;
                }}},[_c('a-button',{staticClass:"ellipsis",staticStyle:{"text-align":"left"},attrs:{"block":""}},[_vm._v(_vm._s(_vm.selectedProject.name))])],1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"发票类型"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'type',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'type',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}],attrs:{"button-style":"solid"}},_vm._l((_vm.invoiceTypeList),function(item){return _c('a-radio-button',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"开票日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'invoiceDate',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'invoiceDate',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"发票号码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'invoiceCode',
                { rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'invoiceCode',\n                { rules: [{ required: true, message: '请输入！' }] },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"销方名称"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'salesUnitId',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'salesUnitId',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}]},_vm._l((_vm.saleList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-model-item',[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("购方名称")]),_c('CompanySelector',{on:{"change":(company) => (_vm.selectedCompany = company)}},[_c('a-button',{staticClass:"ellipsis",staticStyle:{"text-align":"left"},attrs:{"block":""}},[(_vm.selectedCompany.name)?_c('div',{staticClass:"left text-hidden selected"},[_vm._v(_vm._s(_vm.selectedCompany.name))]):_c('div',{staticClass:"left placeholder"})])],1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"税点"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'taxRate',
                { rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'taxRate',\n                { rules: [{ required: true, message: '请输入！' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"min":0,"max":100,"formatter":_vm.$ratioFormatter}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"合计金额(元)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'totalPriceLowercase',
                { rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'totalPriceLowercase',\n                { rules: [{ required: true, message: '请输入！' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"formatter":_vm.$inputNumberFormat}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-model-item',[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("清收人")]),_c('div',[_c('EmployeeSelector',{attrs:{"value":_vm.liquidator.name ? [_vm.liquidator] : []},on:{"change":(arr) => {
                    if (arr.length > 0) {
                      _vm.liquidator = arr[0];
                    } else {
                      _vm.liquidator = {};
                    }
                  }}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[(_vm.liquidator.name)?_c('div',[_vm._v(_vm._s(_vm.liquidator.name))]):_c('div',[_vm._v(" ")])])],1)],1)])],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-model-item',[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("经办人")]),_c('div',[_c('EmployeeSelector',{attrs:{"value":_vm.operator.name ? [_vm.operator] : []},on:{"change":(arr) => {
                    if (arr.length > 0) {
                      _vm.operator = arr[0];
                    } else {
                      _vm.operator = {};
                    }
                  }}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[(_vm.operator.name)?_c('div',[_vm._v(_vm._s(_vm.operator.name))]):_c('div',[_vm._v(" ")])])],1)],1)])],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"业务内容"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'business',
              ]),expression:"[\n                'business',\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("附件")]),_c('FileUpload',{on:{"uploaded":_vm.uploaded}},[_c('a-button',{attrs:{"type":"primary","icon":"upload"}},[_vm._v("上传附件")])],1),_c('div',{staticClass:"link-list"},_vm._l((_vm.fileList),function(item,index){return _c('div',{key:item,staticClass:"link"},[_c('a',{attrs:{"target":"_blank","href":item}},[_vm._v(" "+_vm._s(_vm.$getFileName(item))+" ")]),_c('div',{staticClass:"control",on:{"click":function($event){return _vm.deleteFile(index)}}},[_c('a-icon',{attrs:{"type":"delete"}})],1)])}),0)],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"描述","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remarks']),expression:"['remarks']"}],attrs:{"auto-size":{ minRows: 3 }}})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }