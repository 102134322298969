<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :form="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span slot="label" class="required">项目名称</span>

              <ContractSelector
                @change="
                  (value) => {
                    selectedProject = value;
                  }
                "
              >
                <a-button block style="text-align: left" class="ellipsis">{{ selectedProject.name }}</a-button>
              </ContractSelector>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="发票类型">
              <a-radio-group
                v-decorator="[
                  'type',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                button-style="solid"
              >
                <a-radio-button
                  v-for="item in invoiceTypeList"
                  :key="item.value"
                  :value="item.value"
                >{{ item.name }}</a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="开票日期">
              <a-date-picker
                placeholder
                v-decorator="[
                  'invoiceDate',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="发票号码">
              <a-input
                v-decorator="[
                  'invoiceCode',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="销方名称">
              <a-select
                v-decorator="[
                  'salesUnitId',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
              >
                <a-select-option
                  v-for="item in saleList"
                  :key="item.id"
                  :value="item.id"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-model-item>
              <span class="required" slot="label">购方名称</span>

              <CompanySelector @change="(company) => (selectedCompany = company)">
                <a-button block style="text-align: left" class="ellipsis">
                  <div
                    class="left text-hidden selected"
                    v-if="selectedCompany.name"
                  >{{ selectedCompany.name }}</div>
                  <div class="left placeholder" v-else></div>
                </a-button>
              </CompanySelector>
            </a-form-model-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="税点">
              <a-input-number
                :min="0"
                :max="100"
                :formatter="$ratioFormatter"
                v-decorator="[
                  'taxRate',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="合计金额(元)">
              <a-input-number
                :formatter="$inputNumberFormat"
                v-decorator="[
                  'totalPriceLowercase',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-model-item>
              <span class="required" slot="label">清收人</span>

              <div>
                <EmployeeSelector
                  @change="
                    (arr) => {
                      if (arr.length > 0) {
                        liquidator = arr[0];
                      } else {
                        liquidator = {};
                      }
                    }
                  "
                  :value="liquidator.name ? [liquidator] : []"
                >
                  <a-button block style="text-align: left">
                    <div v-if="liquidator.name">{{ liquidator.name }}</div>
                    <div v-else>&nbsp;</div>
                  </a-button>
                </EmployeeSelector>
              </div>
            </a-form-model-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-model-item>
              <span class="required" slot="label">经办人</span>

              <div>
                <EmployeeSelector
                  @change="
                    (arr) => {
                      if (arr.length > 0) {
                        operator = arr[0];
                      } else {
                        operator = {};
                      }
                    }
                  "
                  :value="operator.name ? [operator] : []"
                >
                  <a-button block style="text-align: left">
                    <div v-if="operator.name">{{ operator.name }}</div>
                    <div v-else>&nbsp;</div>
                  </a-button>
                </EmployeeSelector>
              </div>
            </a-form-model-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="业务内容">
              <a-input v-decorator="[
                  'business',
                ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <span slot="label" class>附件</span>
              <FileUpload @uploaded="uploaded">
                <a-button type="primary" icon="upload">上传附件</a-button>
              </FileUpload>

              <div class="link-list">
                <div class="link" v-for="(item, index) in fileList" :key="item">
                  <a target="_blank" :href="item">
                    {{
                    $getFileName(item) }}
                  </a>
                  <div class="control" @click="deleteFile(index)">
                    <a-icon type="delete" />
                  </div>
                </div>
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="描述" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <a-textarea :auto-size="{ minRows: 3 }" v-decorator="['remarks']" />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button type="primary" html-type="submit" :loading="loading">保存</a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { fetchDetail, edit } from "@/api/invoice";
import { fetchList } from "@/api/setting/sale";
import { mapGetters } from "vuex";
import CompanySelector from "@/components/company-selector";
import FileUpload from "@/components/file-upload";

import moment from "moment";
import ContractSelector from "@/components/contract-selector";

import EmployeeSelector from "@/components/employee-selector";
export default {
  name: "editInvoice",
  mixins: [watermark],

  components: {
    EmployeeSelector,
    ContractSelector,
    CompanySelector,
    FileUpload
  },

  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
      fileList: [],

      id: null,
      detail: {},
      selectedCompany: {},
      selectedProject: {}, // 选择的项目

      saleList: [], // 销方列表
      liquidator: {}, // 清收人
      operator: {} // 经办人
    };
  },

  activated() {
    fetchList().then(res => {
      if (Array.isArray(res.list)) {
        this.saleList = res.list;
      }
    });

    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.id = id;

      fetchDetail({
        id
      }).then(res => {
        this.detail = res;

        this.selectedProject = {
          id: res.contractId,
          name: res.contractName,
          code: res.designCode
        };

        this.liquidator = {
          deptId: res.liquidatorDeptId,
          deptUniqueName: res.liquidatorDeptName,
          userId: res.liquidatorId,
          name: res.liquidatorName
        };

        this.operator = {
          deptId: res.operatorDeptId,
          deptUniqueName: res.operatorDeptName,
          userId: res.operatorId,
          name: res.operatorName
        };

        this.form.setFieldsValue({
          type: res.type,
          invoiceDate: moment(res.invoiceDate),
          totalPriceLowercase: res.totalPriceLowercase,
          salesUnitId: res.salesUnitId,
          invoiceCode: res.invoiceCode,
          remarks: res.remarks,
          taxRate: res.taxRate,
          business: res.business
        });

        this.selectedCompany = {
          id: res.buyerUnitId,
          name: res.buyerUnitName
        };

        this.fileList = res.attachments ? res.attachments.split(",") : [];
      });
    }
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    invoiceStatusList() {
      return this.findDataDict("invoiceStatus");
    },
    invoiceTypeList() {
      return this.findDataDict("invoiceType");
    }
  },

  watch: {
    selectedProject(newValue) {
      if (newValue.signDate) {
        this.form.setFieldsValue({
          invoiceDate: moment(newValue.signDate)
        });
      }
      if (newValue.contractAmount) {
        this.form.setFieldsValue({
          totalPriceLowercase: newValue.contractAmount
        });
      }
    }
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.selectedProject.id) {
            this.$message.error("请选择项目！");
            return;
          }

          if (!this.selectedCompany.id) {
            this.$message.error("请选择购方！");
            return;
          }

          const invoiceDate = values.invoiceDate.format("YYYY-MM-DD");

          this.loading = true;

          edit({
            ...this.detail,
            id: this.id,
            contractId: this.selectedProject.id,
            designCode: this.selectedProject.code,
            ...values,
            invoiceDate,

            buyerUnitId: this.selectedCompany.id,
            buyerUnitName: this.selectedCompany.name,

            // 清收人
            liquidatorDeptId: this.liquidator.deptId,
            liquidatorDeptName: this.liquidator.deptUniqueName,
            liquidatorId: this.liquidator.userId,
            liquidatorName: this.liquidator.name,

            // 经办人
            operatorDeptId: this.operator.deptId,
            operatorDeptName: this.operator.deptUniqueName,
            operatorId: this.operator.userId,
            operatorName: this.operator.name,

            attachments: this.fileList.join(",")
          })
            .then(() => {})
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    uploaded(list) {
      this.fileList = [
        ...this.fileList,
        ...list.map(item => item.completePath)
      ];
    },

    deleteFile(index) {
      this.fileList.splice(index, 1);
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.center {
  padding: 80px 0;
}

.hidden {
  overflow: hidden;
}

.placeholder {
  color: #bfbfbf;
}
.selected {
  color: rgba(0, 0, 0, 0.65);
}

.link-list {
  .link {
    margin: 4px 0;
    padding: 0 12px;
    background-color: #f9f9f9;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .control {
      height: 16px;
      width: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .control:hover {
      color: #1890ff;
    }
  }
}
</style>
